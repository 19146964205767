import React, { useEffect, useRef, useState } from 'react'
import { Post, Endpoint } from '../../api'
import {
  ChatContainer,
  MessagesContainer,
  MessageContainer,
  InputContainer,
  Scrollable,
  DateBox,
  UserBox,
  MessageBox,
} from './Styles'
import { TextInput } from '../Input'

interface Msg {
  message: string
  user: string
  date: string
}

const Chat = () => {
  const [message, setMessage] = useState<string | null>(null)
  const [messages, setMessages] = useState()
  const messagesEndRef = useRef<HTMLDivElement | null>(null)

  const fetchData = async () => {
    const data = {
      lastMessage: 0,
    }
    const res = await Post('get-messages', data, Endpoint.CHAT)
    setMessages(res.data.messages)
  }

  const sendMessage = async () => {
    if (message) {
      const data = {
        message,
      }
      await Post('send-message', data, Endpoint.CHAT)
      setMessage(null)
      fetchData()
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  //  useEffect(() => {
  //    if (messagesEndRef) {
  //      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
  //    }
  //  }, [message, messages])

  useEffect(() => {
    const timer = setInterval(() => {
      fetchData()
    }, 5000)
    return () => clearInterval(timer)
  }, [])

  return (
    <ChatContainer>
      <MessagesContainer>
        <Scrollable>
          {messages &&
            messages.map((msg: Msg, i: number) => (
              <Message key={i} message={msg} />
            ))}
          <div ref={messagesEndRef} />
        </Scrollable>
      </MessagesContainer>
      <InputContainer>
        <TextInput
          onChange={(msg: string) => setMessage(msg)}
          placeholder={'Message'}
          onEnter={() => sendMessage()}
          clearOnEnter
        />
      </InputContainer>
    </ChatContainer>
  )
}

export default Chat

interface MessageProps {
  message: Msg
}
const Message = (props: MessageProps) => {
  return (
    <MessageContainer>
      <UserBox>
        {props.message.user}
        <DateBox>{props.message.date}</DateBox>
      </UserBox>
      <MessageBox>{props.message.message}</MessageBox>
    </MessageContainer>
  )
}
