import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 20px 0;
`

export const Column = styled.div<{ width?: number }>`
  width: ${p => (p.width ? p.width : 100)}%;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ModalContainer = styled.div`
  padding: 20px;
  width: 240px;
  background: ${p => p.theme.black};
`
export const Title = styled.h2`
  margin: 0 0 20px 0;
`
