import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const MenuWrapper = styled.div`
  background: ${p => p.theme.black};
  color: ${p => p.theme.white};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`

export const Menu = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  line-height: 50px;
  display: flex;
`

export const Left = styled.div`
  display: flex;
  width: 70%;
`

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30%;
`

export const MenuTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 0 20px 0 0;
`

export const MenuItem = styled(Link)`
  padding: 0 20px;
  text-decoration: none;
  color: ${p => p.theme.white};

  &:hover {
    background: ${p => p.theme.gray};
    cursor: pointer;
  }
`
export const MenuItemLink = styled.a`
  padding: 0 20px;
  text-decoration: none;
  color: ${p => p.theme.white};

  &:hover {
    background: ${p => p.theme.gray};
    cursor: pointer;
  }
`
