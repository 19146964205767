import React from 'react'
import styled from 'styled-components'
import Spinner from './Spinner'

interface Props {
  children: React.ReactNode
  onClick: () => void
  transparent?: boolean
  loading?: boolean
  disabled?: boolean
}

const SButton = styled.div<{ transparent?: boolean; disabled?: boolean }>`
  background: ${p =>
    p.disabled ? p.theme.gray : p.transparent ? '' : p.theme.gray};
  color: ${p => (p.transparent ? p.theme.gray : p.theme.white)};
  cursor: ${p => (p.disabled ? 'normal' : 'pointer')};
  display: inline-block;
  border-radius: 5px;
  padding: ${p => p.transparent ? '10px 0' : '10px 20px'};

  &:hover {
    background: ${p => (p.transparent ? ' transparent' : p.theme.gray)};
    color: ${p =>
      p.transparent
        ? p.theme.lightGray
        : p.disabled
        ? p.theme.white
        : p.theme.white};
  }
`

const Button = (props: Props) => {
  const handleClick = () => {
    if (!props.disabled) {
      props.onClick()
    }
  }
  return (
    <SButton
      transparent={props.transparent}
      onClick={() => handleClick()}
      disabled={props.disabled}
    >
      {props.loading && <Spinner size={30} />}
      {props.children}
    </SButton>
  )
}

export default Button

const Bbar = styled.div<{ center?: boolean; left?: boolean }>`
  display: flex;
  justify-content: ${p =>
    p.left ? 'flex-start' : p.center ? 'center' : 'flex-end'};

  div {
    margin: ${p =>
      p.left ? '0 20px 0 0' : p.center ? '0 10px' : '0 0 0 20px'};
  }
`

export const ButtonBar = (props: {
  children: React.ReactNode
  center?: boolean
  left?: boolean
}) => {
  return (
    <Bbar left={props.left} center={props.center}>
      {props.children}
    </Bbar>
  )
}
