import axios from 'axios'
import { config } from './config'

export enum Endpoint {
  API = 'api',
  CHAT = 'chat',
}

enum Method {
  GET = 'get',
  POST = 'post',
}

const Request = async (
  method: Method,
  url: string,
  data?: unknown,
  endpoint?: Endpoint,
) => {
  let baseUrl = config.api
  if (endpoint && endpoint === Endpoint.CHAT) {
    baseUrl = config.chat
  }

  const ls = localStorage.getItem('session')

  let token = 'null'
  if (ls) {
    token = JSON.parse(ls).token
  }

  return await axios({
    method: method,
    url: baseUrl + url,
    data: {
      data: data,
      token: token,
    },
  })
}

export const Post = async (url: string, data: unknown, endpoint?: Endpoint) => {
  return await Request(Method.POST, url, data, endpoint)
}

export const Get = async (url: string, endpoint?: Endpoint) => {
  return await Request(Method.POST, url, endpoint)
}
