import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

export const Left = styled.div`
  width: 20%;
`

export const Right = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  align-items: flex-start;
`
