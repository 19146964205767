import styled from 'styled-components'

export const Container = styled.div`
  background: ${p => p.theme.black};
  margin: 0 auto;
  max-width: 320px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
`

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  margin: 0 0 20px 0;
`

export const Error = styled.div<{ visible: boolean }>`
  box-sizing: border-box;
  display: ${p => (p.visible ? 'block' : 'none')};
  color: ${p => p.theme.white};
  background: ${p => p.theme.red};
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 0 10px 0;
`

