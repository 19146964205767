import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { MenuItem } from './main-menu/Styles'

interface StyledProps {
  visible: boolean
}

const WeeksContainer = styled.div`
  position: relative;
  height: 50px;
`

const Title = styled.div`
  padding: 0 20px;
  text-decoration: none;
  color: ${p => p.theme.white};

  &:hover {
    background: ${p => p.theme.gray};
    cursor: pointer;
  }
`

const WeeksItems = styled.div<StyledProps>`
  display: ${p => (p.visible ? 'flex' : 'none')};
  background: ${p => p.theme.black};
  color: ${p => p.theme.white};
  flex-direction: column;
  width: 200px;
  height: 90vh;
  overflow: auto;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  position: absolute;
`

const SMenuItem = styled(MenuItem)`
  color: ${p => p.theme.white};
`

const Input = styled.input`
  background: ${p => p.theme.black};
  color: ${p => p.theme.almostWhite};
  min-height: 50px;
  width: 200px;
  border: none;
  border-bottom: 1px solid ${p => p.theme.lightGray};
  font-size: 16px;
  padding: 0 20px;

  &:focus {
    outline: none;
  }
`

const WeekSelector = () => {
  const [visible, setVisible] = useState(false)
  const [enteredWeek, setEnteredWeek] = useState()
  const [year, setYear] = useState('2020')
  const history = useHistory()

  useEffect(() => {
    const date = new Date()
    setYear(String(date.getFullYear()))
  }, [])

  const weeks = []
  for (let i = 1; i <= 52; i -= -1) {
    weeks.push(i)
  }
  return (
    <WeeksContainer
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <Title>Vecka</Title>
      <WeeksItems visible={visible}>
        <TextInput
          onEnter={() => history.push(`/week/${enteredWeek}`)}
          onChange={(str: string) => setEnteredWeek(str)}
        />
        {weeks.map((week: number) => (
          <SMenuItem key={week} to={`/week/${week}/${year}`}>
            {week}
          </SMenuItem>
        ))}
      </WeeksItems>
    </WeeksContainer>
  )
}

export default WeekSelector

export const TextInput = (props: {
  onChange: (str: string) => void
  onEnter: () => void
}) => {
  const handleChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    props.onChange(e.currentTarget.value)
    if (props.onEnter && e.key === 'Enter') {
      props.onEnter()
    }
  }

  return (
    <Input
      placeholder={'Vecka'}
      onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleChange(e)}
    />
  )
}
