import React from 'react'
import styled from 'styled-components'

interface Props {
  disabled?: boolean
  onChange: (str: string) => void
  placeholder?: string
  onEnter?: () => void
  clearOnEnter?: boolean
}

const BaseInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  padding: 0 10px;
  font-size: 14px;
  margin: 0 0 10px 0;
  border-radius: 5px;
  border: 1px solid ${p => p.theme.gray};
  background: ${p => p.theme.gray};
  color: ${p => p.theme.lightGray};

  &:focus {
    outline: none;
    border: 1px solid ${p => p.theme.gray};
  }
`

const Text = styled(BaseInput).attrs({ type: 'text' })``
const Password = styled(BaseInput).attrs({ type: 'password' })``
const Date = styled(BaseInput).attrs({ type: 'date' })``

export const TextInput = (props: Props) => {
  const handleChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    props.onChange(e.currentTarget.value)
    if (props.onEnter && e.key === 'Enter') {
      props.onEnter()
      if (props.clearOnEnter) {
        e.currentTarget.value = ''
      }
    }
  }

  return (
    <Text
      placeholder={props.placeholder}
      onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleChange(e)}
    />
  )
}

export const PasswordInput = (props: Props) => {
  const handleChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    props.onChange(e.currentTarget.value)
    if (props.onEnter && e.key === 'Enter') {
      props.onEnter()
      if (props.clearOnEnter) {
        e.currentTarget.value = ''
      }
    }
  }

  return (
    <Password
      placeholder={props.placeholder}
      onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleChange(e)}
    />
  )
}

export const DateInput = (props: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.currentTarget.value)
  }

  return (
    <Date
      placeholder={props.placeholder}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
    />
  )
}
