import styled from 'styled-components'

export const Container = styled.div`
  background: ${p => p.theme.darkBlack};
  width: 100vw;
  height: calc(100vh - 60px);
  overflow: auto;
  padding: 60px 0 0 0;
  color: ${p => p.theme.almostWhite};
`

export const ContentContainer = styled.div<{ width?: number }>`
  max-width: ${p => (p.width ? p.width : '1200')}px;
  margin: 0 auto;
`
