import styled from 'styled-components'

export const ChatContainer = styled.div`
  width: calc(100% - 40px);
  height: 500px;
  background: ${p => p.theme.black};
  border-radius: 10px;
  padding: 20px;
`

export const MessagesContainer = styled.div`
  height: calc(100% - 40px);
`

export const Scrollable = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
`

export const InputContainer = styled.div`
  width: 100%;
  height: 40px;
`

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 30px;
`

export const DateBox = styled.div`
  text-align: right;
  font-size: 12px;
  color: ${p => p.theme.gray};
`

export const UserBox = styled.div`
  padding: 0 5px 0 0;
  margin: 0 5px 0 0;
  min-width: 100px;
  text-align: right;
  border-right: 1px solid ${p => p.theme.gray};
  text-transform: capitalize;
  line-height: 20px;
`

export const MessageBox = styled.div`
  font-size: 12px;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 1px 0 0 0;
  line-height: 20px;

  ::first-letter {
    text-transform: capitalize;
  }
`
