import React from 'react'
import {
  MenuWrapper,
  Menu,
  Left,
  Right,
  MenuTitle,
  MenuItem,
  MenuItemLink,
} from './Styles'
import WeekSelector from '../WeekSelector'

const MainMenu = () => {
  return (
    <MenuWrapper>
      <Menu>
        <Left>
          <MenuTitle>GeoScore</MenuTitle>
          <MenuItem to={'/'}>Hem</MenuItem>
          <WeekSelector />
          <MenuItem to={'/toplist'}>Topplista</MenuItem>
          <MenuItemLink
            href={'https://www.geoguessr.com/daily-challenges'}
            target={'_blank'}
          >
            Dagens utmaning
          </MenuItemLink>
        </Left>
        <Right>
          <MenuItem to={'/logout'}>Logga ut</MenuItem>
        </Right>
      </Menu>
    </MenuWrapper>
  )
}

export default MainMenu
