import React from 'react'
import { Overlay, ModalContainer, ModalBox, Close } from './Styles'

interface Props {
  children: React.ReactNode
  visible: boolean
  close: () => void
  showClose?: boolean
}

const Modal = (props: Props) => {
  return (
    <>
      <Overlay visible={props.visible} onClick={() => props.close()} />
      <ModalContainer visible={props.visible}>
        <ModalBox>
          <Close onClick={() => props.close()} visible={props.showClose}>
            x
          </Close>
          {props.children}
        </ModalBox>
      </ModalContainer>
    </>
  )
}

export default Modal
