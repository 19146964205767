import styled from 'styled-components'

const Spinner = styled.div<{ size?: number; invertColor?: boolean }>`
  display: inline-block;
  width: ${p => (p.size ? p.size : '80')}px;
  height: ${p => (p.size ? p.size : '80')}px;
  position: absolute;
  top: 50%;
  margin-top: -${p => (p.size ? p.size / 2 : '40')}px;
  left: 50%;
  margin-left: -${p => (p.size ? p.size / 2 : '40')}px;

  &:after {
    content: ' ';
    display: block;
    width: ${p => (p.size ? p.size * 0.8 : '80')}px;
    height: ${p => (p.size ? p.size * 0.8 : '80')}px;
    border-radius: 50%;
    border: ${p => (p.size ? p.size * 0.075 : '6')}px solid
      ${p => (p.invertColor ? p.theme.black : p.theme.white)};
    border-color: ${p => (p.invertColor ? p.theme.black : p.theme.white)}
      transparent ${p => (p.invertColor ? p.theme.black : p.theme.white)}
      transparent;
    animation: lds-dual-ring 2.5s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default Spinner
