import React, { useState, useEffect } from 'react'
import { TextInput } from '../../Input'
import { ModalContainer, Title } from './Styles'
import Button, { ButtonBar } from '../../Button'
import { Post } from '../../../api'

interface Props {
  close: () => void
  reload: () => void
}

const AddScore = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState()

  const saveScore = async () => {
    if (value.length > 0) {
      setLoading(true)
      const data = {
        score: value,
      }
      const res = await Post('add-score', data)
      console.log(res.data)

      if (res.data.status === 'success') {
        setLoading(false)
        props.reload()
        props.close()
      } else {
        setLoading(false)
        if (res.data.reason === 'ChallengeDoesNotExists') {
          setError('Dagens utmaning saknas')
        }
        if (res.data.reason === 'ScoreAlreadyExists') {
          setError('Poäng redan registrerade idag')
        }
      }
    }
  }

  useEffect(() => {
    if (value.length > 0 && value.length <= 5) {
      if (/^\d+$/.test(value)) {
        setDisabled(false)
      }
    } else {
      setDisabled(true)
    }
  }, [value])

  const handleInput = (val: string) => {
    setValue(val.replace(',',''))
  }

  return (
    <ModalContainer>
      <Title>Lägg till poäng</Title>
      {error}
      <TextInput
        onChange={(val: string) => handleInput(val)}
        placeholder="poäng"
      />
      <ButtonBar>
        <Button onClick={() => props.close()} transparent>
          Avbryt
        </Button>
        <Button
          disabled={disabled}
          loading={loading}
          onClick={() => saveScore()}
        >
          Spara
        </Button>
      </ButtonBar>
    </ModalContainer>
  )
}

export default AddScore
