import React, { useState } from 'react'
import styled from 'styled-components'
import { Score } from '../Types'

interface Props {
  scores: Score[]
  title: string
  showEdit?: boolean
  width?: number
}

const Card = styled.div<{ width?: number }>`
  background: ${p => p.theme.black};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  margin-bottom: 20px;
  width: calc(${p => (p.width ? p.width : '20')}% - 60px);
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 400;
  border-bottom: 2px solid ${p => p.theme.gray};
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  line-height: 25px;
`

const ScoreContainer = styled.div`
  border-bottom: 1px solid ${p => p.theme.gray};
  display: flex;
  justify-content: space-between;
`

const Name = styled.div`
  font-weight: 500;
  flex: 2 0 0;
  text-transform: capitalize;
`
const ScoreBox = styled.div`
  font-weight: 400;
  flex: 1 0 0;
  text-align: right;
`
const Avg = styled.div`
  font-weight: 200;
  text-align: right;
  flex: 1 0 0;
`

const RegisterScore = styled.div<{ visible: boolean }>`
  display: ${p => (p.visible ? 'block' : 'none')};
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  transition: background 0.1s ease-in-out;

  &:hover {
    background: #f0f;
  }
`

const Scorecard = (props: Props) => {
  const [hovering, setHovering] = useState(false)

  const days = [
    'Måndag',
    'Tisdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lördag',
    'Söndag',
    '',
  ]

  const date = Date.parse(props.title)
  let day = 7
  if (date !== NaN) {
    day = new Date(date).getDay() - 1
  }

  return (
    <Card
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      width={props.width}
    >
      <Title>
        {days[day]} {props.title}
        {props.showEdit && (
          <RegisterScore visible={hovering} onClick={() => alert('Add score')}>
            <span role="img" aria-label="edit">
              ✏️
            </span>
          </RegisterScore>
        )}
      </Title>
      {props.scores.map((score: Score, i: number) => (
        <ScoreContainer key={i}>
          <Name>{score.user}</Name>
          <ScoreBox>{score.score}</ScoreBox>
          {score.avg && <Avg>{score.avg}</Avg>}
          {score.toLeader && score.toLeader > 0 ? (
            <Avg> -{score.toLeader}</Avg>
          ) : (
            <Avg></Avg>
          )}
        </ScoreContainer>
      ))}
    </Card>
  )
}

export default Scorecard
