import styled from 'styled-components'

export const Overlay = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  display: ${p => (p.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 0;
`

export const ModalContainer = styled.div<{ visible: boolean }>`
  display: ${p => (p.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`

export const ModalBox = styled.div`
  background: ${p => p.theme.white};
  min-width: 240px;
  max-width: 960px;
  max-height: calc(100vh - 100px);
  position: absolute;
  top: 100px;
  margin: 0 auto;
  z-index: 10;
  overflow: auto;
`

export const Close = styled.div<{ visible?: boolean }>`
  display: ${p => (p.visible ? 'block' : 'none')};
  position: absolute;
  top: 5px;
  right: 5px;
  background: ${p => p.theme.gray};
  color ${p => p.theme.white};
  font-weight: bold;
  width: 28px;
  line-height: 27px;
  height: 28px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background: ${p => p.theme.lightGray};
    color: ${p => p.theme.gray};

  }
`
