import React, { useState } from 'react'
import { Container, Title, Error } from './Styles'
import { TextInput, PasswordInput } from '../../Input'
import Button, { ButtonBar } from '../../Button'
import { doLogin } from '../../../auth'

interface Props {
  setSession: (d: { user: string; token: string }) => void
}

const Login = (props: Props) => {
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState()

  const login = async () => {
    if (username && password) {
      const res: { user: string; token: string } | boolean = await doLogin(
        username,
        password,
      )
      if (res) {
        props.setSession({
          user: res.user,
          token: res.token,
        })
      } else {
        setError('Fel användarnamn eller lösenord')
      }
    } else {
      setError('Du måste ange både användarnamn och lösenord')
    }
  }

  return (
    <Container>
      <Title>GeoScore</Title>
      <Error visible={error ? true : false}>{error}</Error>
      <TextInput
        onChange={(str: string) => setUsername(str)}
        placeholder={'Användarnamn'}
        onEnter={() => login()}
      />
      <PasswordInput
        onChange={(str: string) => setPassword(str)}
        placeholder={'Lösenord'}
        onEnter={() => login()}
      />
      <ButtonBar>
        <Button onClick={() => login()}>Logga in</Button>
      </ButtonBar>
    </Container>
  )
}

export default Login
