import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  setSession: (d: { user: string; token: string }) => void
}

const Logout = (props: Props) => {
  const history = useHistory()

  useEffect(() => {
    localStorage.removeItem('session')
    props.setSession({
      user: '',
      token: '',
    })
    history.push('/')
  })

  return <></>
}

export default Logout
