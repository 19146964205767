import React, { useEffect, useState, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './Theme'
import BaseLayout, { Content } from './components/layout/base/BaseLayout'
import Login from './components/pages/login/Login'
import MainMenu from './components/main-menu/MainMenu'
import Routes from './components/Routes'
import { isAuthenticated } from './auth'
import { Context } from './userContext'
import Logout from './components/pages/login/Logout'

const App = () => {
  const [session, setSession] = useState({
    user: '',
    token: '',
  })

  useEffect(() => {
    const auth = isAuthenticated()
    if (auth) {
      setSession(auth)
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let routes

  if (session.token.length === 0) {
    routes = (
      <Switch>
        <Route>
          <Login setSession={d => setSession(d)} />
        </Route>
      </Switch>
    )
  }

  if (session.token.length > 0) {
    routes = (
      <>
        <MainMenu />
        <Content width={1600}>
          <Routes />
        </Content>
      </>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <BaseLayout>
        <BrowserRouter>
          <Context.Provider
            value={{ user: session.user, token: session.token }}
          >
            <Suspense fallback={'Suspensed loading'}>{routes}</Suspense>
            <Route path={'/logout'}>
              <Logout setSession={d => setSession(d)} />
            </Route>
          </Context.Provider>
        </BrowserRouter>
      </BaseLayout>
    </ThemeProvider>
  )
}

export default App
