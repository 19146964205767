import React, { useContext, useState } from 'react'
import { Context } from '../../../userContext'
import { DateInput } from '../../Input'
import Button from '../../Button'
import { Post } from '../../../api'

const Week = () => {
  const token = useContext(Context)
  const [date, setDate] = useState()
  console.log(token)

  const handleChange = (str: string) => {
    console.log(str)
    setDate(str)
  }

  const submit = async () => {
    console.log('submit')
    const data = {
      date,
    }
    const res = await Post('add-challenge', data)
    console.log(res)
  }

  return (
    <>
      <h1>Lägg till</h1>
      <DateInput onChange={(str: string) => handleChange(str)} />
      <Button onClick={() => submit()}>SKapa</Button>
    </>
  )
}

export default Week
