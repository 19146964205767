const devSettings = {
  api: 'http://127.0.0.1:8000/api/',
  chat: 'http://127.0.0.1:8000/chat/',
}

const prodSettings = {
  api: 'https://gsbeta.ooskar.com/api/',
  chat: 'https://gsbeta.ooskar.com/chat/',
}

export const config =
  process.env.NODE_ENV === 'development' ? devSettings : prodSettings
