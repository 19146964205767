import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Get } from '../../../api'
import Button, { ButtonBar } from '../../Button'
import Scorecard from '../../Scorecard'
import { Challenge } from '../../../Types'
// import { Context } from '../../../userContext'
import Modal from '../../modal/Modal'
import AddScore from './AddScore'
import { Container, Column, Row } from './Styles'
import Chat from '../../chat/Chat'

const Week = () => {
  const [loading, setLoading] = useState(true)
  const [scores, setScores] = useState()
  const [challenges, setChallenges] = useState()
  const [week, setWeek] = useState()
  const params: { week?: string; year?: string } = useParams()
  const [showModal, setShowModal] = useState(false)

  //  const token = useContext(Context)

  const fetchData = async () => {
    setLoading(true)
    let res
    if (!params.week || !params.year) {
      res = await Get('current-week')
    } else {
      res = await Get(`week/${params.week}/${params.year}/`)
    }

    setWeek(res.data.week)
    setScores(res.data.scores)
    setChallenges(res.data.challenges)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [params])

  return (
    <>
      <h1>Vecka {week}</h1>
      <ButtonBar left>
        <Button onClick={() => fetchData()}>Uppdatera</Button>
        <Button onClick={() => setShowModal(true)}>Registrera poäng</Button>
      </ButtonBar>

      {loading && <div>Loading</div>}
      {!loading && (
        <>
          <Container>
            <Column width={72}>
              <Row>
                {challenges.map((challenge: Challenge, i: number) => (
                  <Scorecard
                    key={i}
                    scores={challenge.scores}
                    title={challenge.date}
                  />
                ))}
              </Row>
              <Row>
                <Scorecard scores={scores} title={'Topplista'} width={40} />
              </Row>
            </Column>
            <Column width={28}>
              <Chat />
            </Column>
          </Container>
          {showModal && (
            <Modal visible close={() => setShowModal(false)}>
              <AddScore
                close={() => setShowModal(false)}
                reload={() => fetchData()}
              />
            </Modal>
          )}
        </>
      )}
    </>
  )
}

export default Week
