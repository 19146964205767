const colors = {
  black: '#222',
  darkBlack: '#111',
  white: '#fff',
  almostWhite: '#f1f1f1',
  gray: '#444',
  lightGray: '#d8d8d8',
  veryLightGray: '#e8e8e8',
  blue: '#a2ade0',
  red: '#f00',
}

const theme = {
  ...colors,
}

export default theme
