import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Week from './pages/week/Week'
import Toplist from './pages/toplist/Toplist'
import AddChallenge from './pages/add-challenge/AddChallenge'

const Routes = () => {
  return (
    <Switch>
      <Route exact path={'/'}>
        <Week />
      </Route>
      <Route path={'/week/:week/:year'}>
        <Week />
      </Route>
      <Route path={'/current-week'}>
        <Week />
      </Route>
      <Route path={'/toplist'}>
        <Toplist />
      </Route>
      <Route path={'/add-challenge'}>
        <AddChallenge />
      </Route>
    </Switch>
  )
}

export default Routes
