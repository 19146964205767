import React, { useEffect, useState } from 'react'
import { Get } from '../../../api'
import Scorecard from '../../Scorecard'
import { Container, Left, Right } from './Styles'

const Toplist = () => {
  const [loading, setLoading] = useState(true)
  const [top, setTop] = useState()
  const [avg, setAvg] = useState()
  const [worst, setWorst] = useState()
  const [weeklyWinner, setWeeklyWinner] = useState()
  const [total, setTotal] = useState()

  const fetchData = async () => {
    const res = await Get('toplist')
    const ww = await Get('weekly-winner')
    setTop(res.data.top)
    setAvg(res.data.avg)
    setWorst(res.data.worst)
    setTotal(res.data.total)

    const weeklyWinner = ww.data.weeklyWinners.map(
      (winner: { user: string; week: number; score: number }) => ({
        ...winner,
        user: `${winner.week}: ${winner.user}`,
      }),
    )

    setWeeklyWinner(weeklyWinner)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <h1>Toplist/bottomlist</h1>
      {!loading && (
        <Container>
          <Left>
            <Scorecard
              width={100}
              title={'Veckovinnare'}
              scores={weeklyWinner}
            />
          </Left>
          <Right>
            <Scorecard title={'Högst poäng'} scores={top} />
            <Scorecard title={'Lägst poäng'} scores={worst} />
            <Scorecard title={'Högst genomsnitt'} scores={avg} />
            <Scorecard title={'Total poäng'} scores={total} />
          </Right>
        </Container>
      )}
    </>
  )
}

export default Toplist
