import { Post } from './api'

export const isAuthenticated = () => {
  const lsSession = localStorage.getItem('session')
  if (lsSession) {
    const session = JSON.parse(lsSession)

    if (!session) {
      return false
    }

    const expirationDate = new Date(session.expirationDate)
    const today = new Date()

    if (today <= expirationDate) {
      return {
        user: session.username,
        token: session.token,
      }
    }
  }

  return false
}

export const doLogin = async (username: string, password: string) => {
  const user = {
    username: username,
    password: password,
  }

  const res = await Post('login', user)
  try {
    if (res.status === 200) {
      if (res.data.status === 'success') {
        localStorage.setItem(
          'session',
          JSON.stringify({
            username: res.data.username,
            token: res.data.token,
            expirationDate: res.data.expiration_date,
          }),
        )
        return {
          user: res.data.username,
          token: res.data.token,
        }
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (e) {
    console.error(e)

    return false
  }
}
