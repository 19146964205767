import React from 'react'
import { Container, ContentContainer } from './Styles'

interface Props {
  children: React.ReactNode
}

const BaseLayout = (props: Props) => {
  return <Container>{props.children}</Container>
}

export default BaseLayout

export const Content = (props: {
  children: React.ReactNode
  width?: number
}) => {
  return (
    <ContentContainer width={props.width}>{props.children}</ContentContainer>
  )
}
